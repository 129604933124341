<template>
  <div>
    <h3>F2F Registrants</h3>
    <vs-card style="margin-top: 1%">
    <vs-table stripe :data="f2f_details" >
      <template slot="thead">
        <vs-th> City Class </vs-th>
        <vs-th> GM </vs-th>
        <vs-th v-for="(city, index) in cities" :key="`city-heading-${index}`">
          {{ city }} <br />
          {{ getMomentDateFromTimestamp(dates[city]) }}
        </vs-th>
      </template>

      <template>
        <vs-tr
          v-for="(city_classification, index) in city_classifications"
          :key="`cc-${index}`"
        >
          <vs-td>
            {{ city_classification.cc }}
          </vs-td>
          <vs-td>
            {{ city_classification.gm }}
          </vs-td>
          <vs-td v-for="(city, index) in cities" :key="`city-${index}`">
            <div
              v-on:click="getDetails(city_classification.gm, city)"
              style="color: #9666f6"
            >
              {{
                matrix[city_classification.gm][city] === undefined
                  ? ""
                  : matrix[city_classification.gm][city].count
              }}
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vs-card>
    <!-- Spoc Count -->
    <vs-card style="margin-top: 5%" v-if="f2f_spoc_details.length > 0">
      <div slot="header">
        <h5>
          {{ f2f_registrants_details.gm_name }} - {{ f2f_registrants_details.city }}
        </h5>
      </div>
      <vs-table stripe :data="f2f_spoc_details" max-items="10" pagination>
        <template slot="thead">
          <vs-th> City Class </vs-th>
          <vs-th> Spoc Name </vs-th>
          <vs-th> Count </vs-th>
          <vs-th> Type </vs-th>
          <vs-th> Webinar Date </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].city_classification">
              {{ data[indextr].city_classification }}
            </vs-td>

            <vs-td :data="data[indextr].spoc_name">
              {{ data[indextr].spoc_name }}
            </vs-td>

            <vs-td :data="data[indextr].count" style="color: #9666f6">
              <div
                v-on:click="
                  getSpocDetails(
                    data[indextr].spoc_id,
                    data[indextr].city,
                    data[indextr].spoc_name,
                    data[indextr].count
                  )
                "
              >
                {{ data[indextr].count }}
              </div>
            </vs-td>
            <vs-td :data="data[indextr].type">
              <span v-if="data[indextr].type == 'face_to_face'"> Face To Face</span>
              <span v-if="data[indextr].type == 'zoom'">Zoom</span>
            </vs-td>

            <vs-td :data="data[indextr].webinar_date">
              {{ getSpocWebinarDateFromTimestamp(data[indextr].webinar_date) }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>

    <vs-card style="margin-top: 5%" v-if="spoc_candidate_details.length > 0">
      <div slot="header">
        <h5>{{ spoc_header_details.spoc_name }} - {{ spoc_header_details.count }}</h5>
      </div>
      <vs-table stripe :data="spoc_candidate_details" max-items="10" pagination>
        <template slot="thead">
          <vs-th> Can-ID </vs-th>
          <vs-th> Name </vs-th>
          <vs-th> Level </vs-th>
          <vs-th> City </vs-th>
          <vs-th> Course </vs-th>
          <vs-th> Type </vs-th>
          <vs-th> Webinar Date </vs-th>
          <vs-th> Last Call </vs-th>
          <vs-th> Action </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].candidate_id">
              {{ data[indextr].candidate_id }}
            </vs-td>

            <vs-td :data="data[indextr].candidate_name">
              {{ data[indextr].candidate_name }}
            </vs-td>
            <vs-td :data="data[indextr].level">
              {{ data[indextr].level }}
            </vs-td>
            <vs-td :data="data[indextr].candidate_id">
              {{ data[indextr].city }}
            </vs-td>

            <vs-td :data="data[indextr].candidate_id">
              {{ data[indextr].course }}
            </vs-td>
            <vs-td :data="data[indextr].candidate_id">
              <span v-if="data[indextr].type == 'face_to_face'"> Face To Face</span>
              <span v-if="data[indextr].type == 'zoom'">Zoom</span>
            </vs-td>

            <vs-td :data="data[indextr].webinar_date">
              {{ getSpocWebinarDate(data[indextr].webinar_date) }}
            </vs-td>
            <vs-td :data="data[indextr].last_call">
              {{ getSpoclastCallDate(data[indextr].last_call) }}
              <!-- {{ data[indextr].last_call }} -->
            </vs-td>

            <vs-td :data="data[indextr].candidate_id">
              <div @click="actionData(data[indextr].mwb_id)">
                <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import constants from "../../../constants.json";
export default {
  data: () => ({
    f2f_details: [],
    f2f_spoc_details: [],
    registrants_details: [],
    cities: [],
    city_classifications: [],
    dates: {},
    matrix: null,
    f2f_registrants_details: {},
    spoc_candidate_details: [],
    spoc_header_details: {},
  }),
  mounted() {
    this.getF2fRegistrant();
  },
  methods: {
    getF2fRegistrant() {
      this.$vs.loading();
      let url = `${constants.MILES_WEBINARS}f2fStatsData`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.f2f_details = response.data;
          this.processGMData(response.data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getMomentDateFromTimestamp(value) {
      var dateString = moment.unix(value).format("DD-MMM-YYYY");
      return dateString;
    },
    getWebinarDateFromTimestamp(value) {
      var dateString = moment.unix(value).format("DD-MMM-YYYY");
      return dateString;
    },
    getSpocWebinarDateFromTimestamp(value) {
      var dateString = moment.unix(value).format("DD-MMM-YYYY");
      return dateString;
    },
    getSpocWebinarDate(value) {
      var dateString = moment.unix(value).format("DD-MMM-YYYY");
      return dateString;
    },
    getSpoclastCallDate(value) {
      var dateString = moment.unix(value).format("DD-MMM-YYYY");
      return dateString;
    },
    processGMData(data) {
      const matrix = {};
      const cities = [];
      const dates = {};
      const ccs = {};
      const ccs_array = [];

      // get list of cities
      data.forEach((city_gm_count) => {
        if (!cities.includes(city_gm_count.city)) {
          cities.push(city_gm_count.city);
        }
      });

      // sort cities in ascending order
      cities.sort();
      console.log("cities", cities);

      data.forEach((city_gm_count) => {
        if (dates[city_gm_count.city] === undefined) {
          dates[city_gm_count.city] = city_gm_count.webinar_date;
        }
      });

      data.forEach((city_gm_count) => {
        if (city_gm_count.gm_name === null) {
          city_gm_count.gm_name = "Ex employee";
        }
        if (city_gm_count.city_classification === null) {
          city_gm_count.city_classification = "NA";
        }

        if (ccs[city_gm_count.city_classification] === undefined) {
          ccs[city_gm_count.city_classification] = city_gm_count.gm_name;
        }
        if (matrix[city_gm_count.gm_name] === undefined) {
          matrix[city_gm_count.gm_name] = {};
        }

        matrix[city_gm_count.gm_name][city_gm_count.city] = {
          count: city_gm_count.count,
          city_classification: city_gm_count.city_classification,
        };
      });

      Object.keys(ccs).forEach((cc) => {
        ccs_array.push({
          cc: cc,
          gm: ccs[cc],
        });
      });

      console.log("ccs_array", ccs_array);

      const ccs_array_sorted = ccs_array.sort((a, b) => a.cc.localeCompare(b.cc));
      // console.log("ccs_array_sorted", ccs_array_sorted);

      ccs_array_sorted.forEach((cc) => {
        const city_classification = cc.cc;
        const gm_name = cc.gm;
        let city_wise_data = [];
        cities.forEach((city) => {
          if (matrix[gm_name][city] === undefined) {
            city_wise_data.push("");
          } else {
            city_wise_data.push(matrix[gm_name][city].count);
          }
        });
        console.log(`${city_classification} ${gm_name}  ${city_wise_data.toString()}`);
      });
      this.dates = dates;
      this.cities = cities;
      this.matrix = matrix;
      this.city_classifications = ccs_array_sorted;
    },
    getDetails(gm_name, city) {
      console.log("djhsdfgjf", city);
      this.spoc_candidate_details = [];
      this.getF2fStatsSpocData(gm_name, city);
    },
    getF2fStatsSpocData(gm_name, city) {
      this.f2f_registrants_details = {
        gm_name: gm_name,
        city: city,
      };
      this.$vs.loading();
      let url = `${constants.MILES_WEBINARS}f2fStatsDataSpoc?gm_name=${gm_name}&city=${city}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.f2f_spoc_details = response.data;
          console.log("Spoc Details", response.data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getSpocDetails(id, city, spoc_name, count) {
      this.getF2fStatsIndividualData(id, city);
      this.spoc_header_details = {
        spoc_name: spoc_name,
        count: count,
      };
    },
    getF2fStatsIndividualData(id, city) {
      this.$vs.loading();
      let url = `${constants.MILES_WEBINARS}f2fStatsIndividualData?spoc_id=${id}&city=${city}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.spoc_candidate_details = response.data;
          // console.log("Spoc Candidate details ", response.data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    actionData(mwbId) {
      this.openBigPopup(mwbId);
    },
  },
};
</script>
