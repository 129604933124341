var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("F2F Registrants")]),_c('vs-card',{staticStyle:{"margin-top":"1%"}},[_c('vs-table',{attrs:{"stripe":"","data":_vm.f2f_details}},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" City Class ")]),_c('vs-th',[_vm._v(" GM ")]),_vm._l((_vm.cities),function(city,index){return _c('vs-th',{key:("city-heading-" + index)},[_vm._v("\n        "+_vm._s(city)+" "),_c('br'),_vm._v("\n        "+_vm._s(_vm.getMomentDateFromTimestamp(_vm.dates[city]))+"\n      ")])})],2),_vm._l((_vm.city_classifications),function(city_classification,index){return _c('vs-tr',{key:("cc-" + index)},[_c('vs-td',[_vm._v("\n          "+_vm._s(city_classification.cc)+"\n        ")]),_c('vs-td',[_vm._v("\n          "+_vm._s(city_classification.gm)+"\n        ")]),_vm._l((_vm.cities),function(city,index){return _c('vs-td',{key:("city-" + index)},[_c('div',{staticStyle:{"color":"#9666f6"},on:{"click":function($event){return _vm.getDetails(city_classification.gm, city)}}},[_vm._v("\n            "+_vm._s(_vm.matrix[city_classification.gm][city] === undefined
                ? ""
                : _vm.matrix[city_classification.gm][city].count)+"\n          ")])])})],2)})],2)],1),(_vm.f2f_spoc_details.length > 0)?_c('vs-card',{staticStyle:{"margin-top":"5%"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h5',[_vm._v("\n        "+_vm._s(_vm.f2f_registrants_details.gm_name)+" - "+_vm._s(_vm.f2f_registrants_details.city)+"\n      ")])]),_c('vs-table',{attrs:{"stripe":"","data":_vm.f2f_spoc_details,"max-items":"10","pagination":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":data[indextr].city_classification}},[_vm._v("\n            "+_vm._s(data[indextr].city_classification)+"\n          ")]),_c('vs-td',{attrs:{"data":data[indextr].spoc_name}},[_vm._v("\n            "+_vm._s(data[indextr].spoc_name)+"\n          ")]),_c('vs-td',{staticStyle:{"color":"#9666f6"},attrs:{"data":data[indextr].count}},[_c('div',{on:{"click":function($event){return _vm.getSpocDetails(
                  data[indextr].spoc_id,
                  data[indextr].city,
                  data[indextr].spoc_name,
                  data[indextr].count
                )}}},[_vm._v("\n              "+_vm._s(data[indextr].count)+"\n            ")])]),_c('vs-td',{attrs:{"data":data[indextr].type}},[(data[indextr].type == 'face_to_face')?_c('span',[_vm._v(" Face To Face")]):_vm._e(),(data[indextr].type == 'zoom')?_c('span',[_vm._v("Zoom")]):_vm._e()]),_c('vs-td',{attrs:{"data":data[indextr].webinar_date}},[_vm._v("\n            "+_vm._s(_vm.getSpocWebinarDateFromTimestamp(data[indextr].webinar_date))+"\n          ")])],1)})}}],null,false,4033522114)},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" City Class ")]),_c('vs-th',[_vm._v(" Spoc Name ")]),_c('vs-th',[_vm._v(" Count ")]),_c('vs-th',[_vm._v(" Type ")]),_c('vs-th',[_vm._v(" Webinar Date ")])],1)],2)],1):_vm._e(),(_vm.spoc_candidate_details.length > 0)?_c('vs-card',{staticStyle:{"margin-top":"5%"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h5',[_vm._v(_vm._s(_vm.spoc_header_details.spoc_name)+" - "+_vm._s(_vm.spoc_header_details.count))])]),_c('vs-table',{attrs:{"stripe":"","data":_vm.spoc_candidate_details,"max-items":"10","pagination":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":data[indextr].candidate_id}},[_vm._v("\n            "+_vm._s(data[indextr].candidate_id)+"\n          ")]),_c('vs-td',{attrs:{"data":data[indextr].candidate_name}},[_vm._v("\n            "+_vm._s(data[indextr].candidate_name)+"\n          ")]),_c('vs-td',{attrs:{"data":data[indextr].level}},[_vm._v("\n            "+_vm._s(data[indextr].level)+"\n          ")]),_c('vs-td',{attrs:{"data":data[indextr].candidate_id}},[_vm._v("\n            "+_vm._s(data[indextr].city)+"\n          ")]),_c('vs-td',{attrs:{"data":data[indextr].candidate_id}},[_vm._v("\n            "+_vm._s(data[indextr].course)+"\n          ")]),_c('vs-td',{attrs:{"data":data[indextr].candidate_id}},[(data[indextr].type == 'face_to_face')?_c('span',[_vm._v(" Face To Face")]):_vm._e(),(data[indextr].type == 'zoom')?_c('span',[_vm._v("Zoom")]):_vm._e()]),_c('vs-td',{attrs:{"data":data[indextr].webinar_date}},[_vm._v("\n            "+_vm._s(_vm.getSpocWebinarDate(data[indextr].webinar_date))+"\n          ")]),_c('vs-td',{attrs:{"data":data[indextr].last_call}},[_vm._v("\n            "+_vm._s(_vm.getSpoclastCallDate(data[indextr].last_call))+"\n            ")]),_c('vs-td',{attrs:{"data":data[indextr].candidate_id}},[_c('div',{on:{"click":function($event){return _vm.actionData(data[indextr].mwb_id)}}},[_c('vs-icon',{attrs:{"icon":"info_outline","size":"small","color":"dark"}})],1)])],1)})}}],null,false,3548636068)},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" Can-ID ")]),_c('vs-th',[_vm._v(" Name ")]),_c('vs-th',[_vm._v(" Level ")]),_c('vs-th',[_vm._v(" City ")]),_c('vs-th',[_vm._v(" Course ")]),_c('vs-th',[_vm._v(" Type ")]),_c('vs-th',[_vm._v(" Webinar Date ")]),_c('vs-th',[_vm._v(" Last Call ")]),_c('vs-th',[_vm._v(" Action ")])],1)],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }